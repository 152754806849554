@font-face {
  font-family: 'roboto-medium';
  src: url(../assets/font/Roboto/Roboto-Medium.ttf);
}

@font-face {
  font-family: 'roboto-regular';
  src: url(../assets/font/Roboto/Roboto-Regular.ttf);
}

@font-face {
  font-family: 'roboto-thin';
  src: url(../assets/font/Roboto/Roboto-Thin.ttf);
}

@font-face {
  font-family: 'roboto-bold';
  src: url(../assets/font/Roboto/Roboto-Bold.ttf);
}

@font-face {
  font-family: "win95";
  src: url(../assets/font/W95FA/w95fa.woff);
}

$font-medium: 'roboto-medium';
$font-regular: 'roboto-regular';
$font-thin: 'roboto-thin';
$font-bold: 'roboto-bold';

$font-win: 'win95';