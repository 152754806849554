.more-app {
  transform: translateY(-200%);
  transition: 0.5s ease-in-out;
  opacity: 0;
  width: 100px;
  height: 60px;
  background-color: #191919;
  position: absolute;
  top: 40px;
  right: 30%;
  border-radius: 5px;
  border: 1px solid rgb(78, 78, 78);
  padding: 10px;
  display: flex;
  flex-wrap: wrap;

  &__image {
    width: 25%;
    height: 50%;
  }


  &.active {
    transform: translate(0);
    transition: 0.5s ease-in-out;
    opacity: 1;
  }
}

@media (min-width: 768px) {
  .more-app {
    right: 10%;
  }
}

@media (min-width: 1024px) {
  .more-app {
    top: auto;
    bottom: 7%;
    transform: translateY(200px);

    &__image {
      padding: 5px;
      cursor: pointer;
      &:hover {
        background-color: #3b3b3b;
      }
    }
  }
}