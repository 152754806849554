@use "src/styles/vars";

.contact {
  background-color: #191919;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100vw;
  height: 80vh;
  border-radius: 10px;
  -webkit-box-shadow: 0px 0px 14px 2px #000000; 
  box-shadow: 0px 0px 14px 2px #000000;
  animation: scale 0.3s ease-in-out;

  &__title-bar {
    background-color: #1B2125;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 5%;
    padding: 5px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom: 1px solid rgb(65, 65, 65);

    &__left {
      display: flex;
      align-items: center;
      width: fit-content;

      &__icon {
        width: 30px;
        height: 30px;
      }

      &__title {
        color: rgb(194, 193, 193);
        font-family: vars.$font-regular;
        margin-left: 5px;
      }
    }

    &__right {
      display: flex;
      justify-content: space-around;
      width: 90px;

      &__icons {
        width: 15px;
        height: 15px; 
      }
    }
  }

  &__content {
    display: flex;
    overflow: hidden;
    height: 93%;
    position: relative;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    &__left {
      color: white;
      font-family: vars.$font-regular;
      width: 95%;
      height: 100%;
      background-color: #242424;
      border-bottom-left-radius: 10px;
      position: absolute;
      transform: translate(-84%);
      transition: 0.5s ease-in-out;

      &.active {
        transform: translate(0);
        transition: 0.5s ease-in-out;
      }

      &__title {
        font-size: 25px;
        margin: 10px;
      }

      &__burger {
        width: 40px;
        height: 30px;
        cursor: pointer;
        position: absolute;
        top: 5px;
        right: 5px;

        & span {
          width: 100%;
          height: 5px;
          background-color: white;
          border-radius: 2.5px;
          display: block;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          transition: 0.5s ease-in-out;

          &::before, &::after {
            content: '';
            position: absolute;
            width: 100%;
            height: 5px;
            background-color: white;
            border-radius: 2.5px;
          }

          &::before {
            transform: translateY(-12.5px);
            transition: 0.5s ease-in-out;
          }

          &::after {
            transform: translateY(12.5px);
            transition: 0.5s ease-in-out;
          }
        }

        &.active {
          & span {
            background-color: transparent;
            transition: 0.5s ease-in-out;

            &::before {
              transform: translateY(0px) rotateZ(45deg);
              transition: 0.5s ease-in-out;
            }

            &::after {
              transform: translateY(0px) rotateZ(-45deg);
              transition: 0.5s ease-in-out;
            }
          }
        }
      }

      a {
        text-decoration: none;
        color: white;
      }

      &__card {
        width: 80%;
        border: 1px solid grey;
        border-radius: 2px;
        margin-bottom: 5px;
        margin-left: 5px;

        &__container {
          display: flex;
          margin: 5px;
          align-items: center;
        }

        &__image {
          width: 40px;
        }

        &__name {
          font-family: vars.$font-medium;
          font-size: 18px;
          margin-left: 10px;
        }

        &__object {
          margin-left: 5px;
        }

        &__hour {
          text-align: end;
          margin: 2px 6px;
        }
      }

    }

    &__right {
      margin-left:20%;
      margin-top: 10px;
      margin-bottom: 10px;
      height: 100%;
    }
  }
}

@media (min-width: 768px) {
  .contact {
    width: 80vw;
    height: 65vh;
    transition: all 0.5s ease-in-out;

    &.active {
      top: 2rem;
      left: 0%;
      transform: none;
      width: 100vw;
      height: 90vh;
      transition: all 0.5s ease-in-out;

      .contact {
        &__content {
          &__left {
            width: 45%;
          }
        }
      }
    }

    &__content {
      &__left {
        width: 50%;
      }
      &__right {
        margin-left: 15%;
        margin-right: 10%;
      }
    }
  }
}

@media (min-width:1024px) {
  .contact {
    width: 40vw;
    top: 55%;
    left: 70%;

    &.active {
      top: 0;
      height: 95vh;

      .contact {
        &__content {
          &__left {
            width: 15%;
          }
        }
      }
    }

    &__title-bar {
      &__left {
        &__title {
          cursor: default;
        }
      }
      &__right {
        width: 100px;
        &__icons {
          padding: 10px;
          margin-right: 2px;
          cursor: pointer;
          &:hover {
            background-color: rgb(86, 88, 88);
          }

          &--close {
            border-top-right-radius: 5px;
            &:hover {
              background-color: red;
            }
          }
        }
      }
    }

    &__content {
      &__left {
        width: 40%;

        &__card {
          transition: 0.5s ease-in-out;
          margin-left: 10px;
          &:hover {
            background-color: #464646;
            transition: 0.2s ease-in-out;
          }
        }
      }
      &__right {
        margin-top: 30px;
        margin-left: 15%;
        margin-right: 10%;
      }
    }
  }
}

.active-card {
  background-color: #363636;
}

@keyframes scale {
  from {
    top: 60%;
    left: 25%;
    transform: scale(0) translate(-100%, 50%);
  }
  to {
    transform: scale(1) translate(-50%, -50%);
  }
}