@use "src/styles/vars";

.browser {
  background-color: #191919;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100vw;
  height: 80vh;
  border-radius: 10px;
  -webkit-box-shadow: 0px 0px 14px 2px #000000; 
  box-shadow: 0px 0px 14px 2px #000000;
  overflow: hidden;
  animation: scale 0.3s ease-in-out;

  &__title-bar {
    background-color: #1B2125;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 5%;
    padding: 5px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom: 1px solid rgb(65, 65, 65);

    &__left {
      display: flex;
      align-items: center;
      width: fit-content;

      &__icon {
        width: 30px;
        height: 30px;
      }

      &__title {
        color: rgb(194, 193, 193);
        font-family: vars.$font-regular;
        margin-left: 5px;
      }
    }

    &__right {
      display: flex;
      justify-content: space-around;
      width: 90px;

      &__icons {
        width: 15px;
        height: 15px; 
      }
    }
  }

  &__content {
    height: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    
    &__web-page {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }
  }
}

@media (min-width: 768px) {
  .browser {
    width: 80vw;
    height: 65vh;
    transition: all 0.5s ease-in-out;

    &.active {
      top: 2rem;
      left: 0%;
      transform: none;
      width: 100vw;
      height: 90vh;
      transition: all 0.5s ease-in-out;
    }
  }
}

@media (min-width:1024px) {
  .browser {
    width: 60vw;
    top: 55%;
    left: 35%;

    &.active {
      top: 0;
      height: 95vh;
    }

    &__title-bar {
      &__left {
        &__title {
          cursor: default;
        }
      }
      &__right {
        width: 100px;
        &__icons {
          padding: 10px;
          margin-right: 2px;
          cursor: pointer;
          &:hover {
            background-color: rgb(86, 88, 88);
          }

          &--close {
            border-top-right-radius: 5px;
            &:hover {
              background-color: red;
            }
          }
        }
      }
    }
  }
}

@keyframes scale {
  from {
    top: 60%;
    left: 25%;
    transform: scale(0) translate(-100%, 50%);
  }
  to {
    transform: scale(1) translate(-50%, -50%);
  }
}