.home-connection {
  background-image: url('../../assets/img/Saint_mary_lake_usa.jpg');
  background-position: center;
  background-size: cover;
  width: 100vw;
  height: 100dvh;

  &__container {
    width: 100vw;
    height: 100dvh;
    backdrop-filter: blur(10px);
    display: flex;
    justify-content: center;
    align-items: center;
  }
}