@use "src/styles/vars";

.network-info {
  transform: translateY(-400%);
  transition: 0.5s ease-in-out;
  opacity: 0;
  width: 200px;
  height: 300px;
  background-color: #191919;
  position: absolute;
  top: 40px;
  right: 2%;
  border-radius: 5px;
  border: 1px solid rgb(78, 78, 78);
  display: flex;
  flex-wrap: wrap;

  &__content {
    width: 100%;
    height: 90%;

    &__cards-group {
      display: flex;
      justify-content: space-around;
      margin-top: 10px;
      margin-bottom: 15px;

      &__card {
        padding: 10px 20px;
        border-radius: 5px;
        background-color: #3A3736;
        transition: 0.5s ease-in-out;

        &__image {
          width: 20px;
        }

        &.active {
          background-color: #8d8c89;
          transition: 0.3s ease-in-out;

          &:hover {
            background-color: #a1a09d;
            transition: 0.3s ease-in-out;
          }
        }
      }
    }

    &__sliders {
      width: 100%;

      &__item {
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin-bottom: 10px;

        &__image {
          width: 30px;
        }

        &__range {
          -webkit-appearance: none;
          width: 70%;
          background: #a8a7a7;
          height: 8px;
          border-radius: 5px;

          &::-webkit-slider-thumb {
            appearance: none;
            width: 18px;
            height: 18px;
            border-radius: 50%;
            border: 5px solid #4b4b4b;
            background: #a8a7a7;
          }
        }
      }
    }
  }

  &__battery {
    display: flex;
    align-items: center;
    width: 100%;
    background-color: #141414;
    padding: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;


    &__image {
      width: 20px;
    }

    &__text {
      color: white;
      font-family: vars.$font-regular;
    }
  }


  &.active {
    transform: translate(0);
    transition: 0.5s ease-in-out;
    opacity: 1;
  }
}

@media (min-width: 768px) {
  .network-info {
    right: 2%;
  }
}

@media (min-width: 1024px) {
  .network-info {
    width: 300px;
    top: auto;
    bottom: 7%;
    transform: translateY(400px);

    &__content {
      &__cards-group {
        &__card {
          padding: 15px 30px;

          &:hover {
            cursor: pointer;
            background-color: #4d4948;
          }
        }
      }

      &__sliders {
        &__item {
          &__range {
            height: 4px;

            &:hover {
              background: #d4d4d4;
            }
            
            &::-webkit-slider-thumb {
              width: 20px;
              height: 20px;
              cursor: pointer;
              
              &:hover {
                border-width: 3px;
              }
            }
          }
        }
      }
    }
  }
}