@use "src/styles/vars";

.trash {
  background-color: #191919;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100vw;
  height: 50vh;
  border-radius: 10px;
  -webkit-box-shadow: 0px 0px 14px 2px #000000; 
  box-shadow: 0px 0px 14px 2px #000000;
  animation: scale 0.3s ease-in-out;

  &__title-bar {
    background-color: #1B2125;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 5%;
    padding: 5px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom: 1px solid rgb(65, 65, 65);

    &__left {
      display: flex;
      align-items: center;
      width: fit-content;

      &__icon {
        width: 30px;
        height: 30px;
      }

      &__title {
        color: rgb(194, 193, 193);
        font-family: vars.$font-regular;
        margin-left: 5px;
      }
    }

    &__right {

      &__icons {
        margin-right: 5px;
        width: 15px;
        height: 15px; 
      }
    }
  }

  &__content {
    width: 100%;
    height: 90%;
    display: flex;
    justify-content: center;
    align-items: center;

    &__text {
      color: rgb(187, 187, 187);
      font-family: vars.$font-regular;
      cursor: pointer;
    }
  }
}

@media (min-width: 768px) {
  .trash {
    width: 60vw;
    height: 50vh;
    transition: all 0.5s ease-in-out;
  }
}

@media (min-width:1024px) {
  .trash {
    width: 40vw;
    top: 30%;
    left: 30%;


    &__title-bar {
      &__left {
        &__title {
          cursor: default;
        }
      }
      &__right {
        &__icons {
          padding: 10px;
          margin-right: 0;
          cursor: pointer;
          &:hover {
            background-color: rgb(86, 88, 88);
          }

          &--close {
            border-top-right-radius: 5px;
            &:hover {
              background-color: red;
            }
          }
        }
      }
    }
  }
}

@keyframes scale {
  from {
    top: 60%;
    left: 25%;
    transform: scale(0) translate(-200%, 50%);
  }
  to {
    transform: scale(1) translate(-50%, -50%);
  }
}