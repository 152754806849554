@use "src/styles/vars";

.project-explorer {
  background-color: #191919;
  position: absolute;
  top: 47%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100vw;
  height: 78vh;
  border-radius: 10px;
  -webkit-box-shadow: 0px 0px 14px 2px #000000; 
  box-shadow: 0px 0px 14px 2px #000000;
  animation: scale 0.3s ease-in-out;

  &__title-bar {
    background-color: #1B2125;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 5%;
    padding: 5px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom: 1px solid rgb(65, 65, 65);

    &__left {
      display: flex;
      align-items: center;
      width: fit-content;

      &__icon {
        width: 30px;
        height: 30px;
      }

      &__title {
        color: rgb(194, 193, 193);
        font-family: vars.$font-regular;
        margin-left: 5px;
      }
    }

    &__right {
      display: flex;
      justify-content: space-around;
      width: 90px;

      &__icons {
        width: 15px;
        height: 15px; 
      }
    }
  }

  &__control-bar {
    background-color: #191919;
    display: flex;
    align-items: center;
    width: 100%;
    height: 10%;
    margin-bottom: 5px;

    &__button {
      display: flex;
      justify-content: space-around;
      width: 25%;

      &--arrow {
        width: 35px;
        height: 35px;
      }
    }

    &__path {
      margin-top: 5px;
      display: flex;
      align-items: center;
      position: relative;
      width: 70%;
      border: 1px solid rgb(65, 65, 65);
      border-radius: 10px;
      padding: 5px;

      &__icon {
        width: 30px;
        height: 30px;
        margin-right: 5px;
      }

      &__name {
        color: white;
        font-family: vars.$font-regular;
        font-size: 13px;

        &__link {
          text-decoration: none;
          color: white;
          padding: 7px;
        }
      }

      &__arrow {
        width: 20px;
        height: 20px;
        position: absolute;
        right: 5px;
      }
    }

    &__search {
      display: none;
    }
  }

  &__content {
    background-color: #191919;
    height: 85%;
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    overflow-y: scroll;
    
    &::-webkit-scrollbar {
      width: 3px;
    }
  
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
  
    &::-webkit-scrollbar-thumb {
      background-color: grey;
      border-radius: 3px;

      &:hover {
        background-color: rgb(187, 187, 187);
      }
    }

    &__card {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex-wrap: wrap;
      padding: 10px;
      margin: 5px;
      height: fit-content;
      width: 80px;
      text-decoration: none;

      &__icon {
        width: 60px;
      }

      &__title {
        margin-top: 10px;
        font-family: vars.$font-regular;
        color: white;
        text-align: center;
      }
    }

    &__details {
      width: 100%;
      color: white;

      &__header {
        width: 100%;

        &__icon {
          width: 100%;
          margin-bottom: 30px;
        }

        &__title {
          font-family: vars.$font-medium;
          font-size: 35px;
          text-align: center;
          margin-bottom: 30px;
        }
      }

      &__image {
        width: 100%;
        margin-bottom: 30px;
      }

      &__description {
        font-size: 20px;
        font-family: vars.$font-regular;
        margin-bottom: 30px;
      }

      &__link {
        font-size: 15px;
        font-family: vars.$font-regular;
        margin-bottom: 10px;

        a {
          text-decoration: none;
          color: rgb(201, 201, 201);
          text-decoration: underline;
        }
      }

      &__github {
        font-size: 15px;
        font-family: vars.$font-regular;
        margin-bottom: 10px;

        a {
          text-decoration: none;
          color: rgb(201, 201, 201);
          text-decoration: underline;
        }
      }
    }
  }
}

@media (min-width: 768px) {
  .project-explorer {
    width: 80vw;
    height: 65vh;
    transition: all 0.5s ease-in-out;

    &.active {
      top: 2rem;
      left: 0%;
      transform: none;
      width: 100vw;
      height: 90vh;
      transition: all 0.5s ease-in-out;
    }

    &__control-bar {
      height: 10%;

      &__button {
        width: 15%;
      }

      &__path {
        width: 82%;
      }
    }

    &__content {
      height: 75%;

      &__details {
        width: 90%;
        margin: 1px auto;

        &__header {
          display: flex;
          align-items: center;

          &__icon {
            width: 50px;
            margin-right: 10px;
          }
        }
      }
    }
  }
}

@media (min-width:1024px) {
  .project-explorer {
    width: 60vw;
    top: 40%;
    left: 40%;

    &.active {
      top: 0;
      height: 95vh;

      & .project-explorer {
        &__control-bar {
          &__button {
            width: 5%;
          }

          &__path {
            width: 65%;
          }
        }
      }
    }

    &__title-bar {
      &__left {
        &__title {
          cursor: default;
        }
      }
      &__right {
        width: 100px;
        &__icons {
          padding: 10px;
          margin-right: 2px;
          cursor: pointer;
          &:hover {
            background-color: rgb(86, 88, 88);
          }

          &--close {
            border-top-right-radius: 5px;
            &:hover {
              background-color: red;
            }
          }
        }
      }
    }

    &__control-bar {
      &__button {
        width: 10%;

        &--arrow {
          padding: 5px;
          border-radius: 5px;
          transition: 0.5s ease-in-out;

          &:hover {
            background-color: #7c7c7c59;
            transition: 0.3s ease-in-out;
            cursor: pointer;
          }
        }
      }

      &__path {
        width: 60%;
        height: 30px;

        &__name {
          &__link {
            border-radius: 5px;
            transition: 0.5s ease-in-out;
            
            &:hover {
              background-color: #7c7c7c59;
              transition: 0.3s ease-in-out;
              cursor: pointer;
            }
          }
        }
      }

      &__search {
        width: 25%;
        display: block;
        margin-left: 1%;

        &--input {
          width: 100%;
          height: 30px;
          background-color: #191919;
          padding: 5px;
          font-size: 15px;
          border: 1px solid rgb(65, 65, 65);
          border-radius: 10px;
          margin-top: 5px;
        }
      }
    }

    &__content {
      &__card {
        border-radius: 5px;
        transition: 0.5s ease-in-out;

        &:hover {
          background-color: #7c7c7c59;
          transition: 0.3s ease-in-out;
          cursor: pointer;
        }
      }

      &__details {
        &__link {
          a {
            text-decoration: none;
            
            &:hover {
              color: rgb(240, 240, 240);
              text-decoration: underline;
            }
          }
        }

        &__github {
          a {
            text-decoration: none;
            
            &:hover {
              color: rgb(240, 240, 240);
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
}

@keyframes scale {
  from {
    top: 60%;
    left: 25%;
    transform: scale(0) translate(-100%, 50%);
  }
  to {
    transform: scale(1) translate(-50%, -50%);
  }
}