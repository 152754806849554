@use "src/styles/vars";

.about-me {
  display: none;
  &.active {
    display: block;
    color: aliceblue;
    background-color: #2f373d;
    animation: toggleDetails 0.5s ease-in-out;
    padding: 10px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  &__content {
    font-family: vars.$font-regular;
    margin-bottom: 20px;
    font-size: 15px;
  }
}

.malt-link {
  text-decoration: none;
  color: #4592d1;
}

.malt-link:hover {
  text-decoration: underline;
  color: #81b1d8;
}

@media (min-width: 768px) {
  .about-me {
    &.active {
      padding: 10px 30px;
    }
    &__content {
      font-size: 17px;
    }
  }
}

@keyframes toggleDetails {
  from {
    opacity: 0;
    transform: translateX(-50%);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}