@use "src/styles/vars";

.work {
  display: none;
  &.active {
    display: block;
    color: aliceblue;
    background-color: #2f373d;
    animation: toggleDetails 0.5s ease-in-out;
    padding: 10px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  &__container {
    font-family: vars.$font-regular;
    font-size: 20px;
    display: flex;

    &__detail {
      border-left: 1px solid grey;
      margin-left: 25px;
      &--subtitle {
        margin-left: 25px;
        font-family: vars.$font-regular;
        font-size: 20px;
        display: flex;
        &__image {
          width: 50px;
          height: 50px;
          margin-right: 10px;
        }
      }

      &__text {
        font-family: vars.$font-regular;
        font-size: 15px;
        margin-bottom: 15px;
        margin-left: 50px;
      }
    }
    
    &__image {
      width: 50px;
      height: 40px;
      margin-right: 10px;
    }
  }

  &__text {
    font-family: vars.$font-regular;
    font-size: 15px;
    margin-bottom: 15px;
  }
}

.bold {
  font-family: vars.$font-bold;
}

@media (min-width: 768px) {
  .work {
    &.active {
      padding: 10px 30px;
    }

    &__text {
      font-size: 18px;
    }
  }
}

@keyframes toggleDetails {
  from {
    opacity: 0;
    transform: translateY(-40%);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}