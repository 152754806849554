@use 'src/styles/vars';

.menu {
  position: absolute;
  bottom: 60px;
  left: 2.5%;
  width: 95%;
  border-radius: 10px;
  z-index: 100;
  -webkit-box-shadow: 0px 0px 14px 2px #000000; 
  box-shadow: 0px 0px 14px 2px #000000;
  opacity: 0;
  transform: translateY(200%);
  transition: 0.5s ease-in-out;

  &--background {
    background-color: #282828a8;
    backdrop-filter: blur(10px);
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 10px;
  }

  &__title {
    font-family: vars.$font-regular;
    color: white;
    margin: 1rem 0 0 1rem;
  }

  &__pin {
    width: 95%;
    justify-self: center;
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: 15px auto;
    text-align: center;

    &-nav {
      text-decoration: none;
    }

    &__container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 0.3rem;
      padding: 0.5rem 0.8rem;
      width: 60px;
      border-radius: 10px;
      text-decoration: none;

      &-icon {
        width: 3rem;
      }

      &-title {
        font-family: vars.$font-regular;
        color: white;
        font-size: 0.8rem;
        margin-top: 0.3rem;
      }
    }
  }

  &__application {
    display: none;
  }

  &__bottom {
    background-color: #1b1b1ba8;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.8rem 1.1rem;
    border-radius: 0 0 10px 10px;
    border-top: 1px solid rgb(139, 139, 139);

    &__profile {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 150px;

      &-image {
        width: 2.2rem;
        height: 2.2rem;
        border-radius: 50%;
        border: 1px solid rgb(218, 218, 218);
      }

      &-title {
        font-family: vars.$font-regular;
        color: rgb(218, 218, 218);
      }
    }

    &__off {
      width: 1.3rem;
      height: 1.3rem;
    }
  }

  &.active {
    opacity: 1;
    transform: translateY(0);
    transition: 0.5s ease-in-out;
  }
}

@media (min-width: 768px) {
  .menu {
    width: 55%;
    left: 22.5%;
  }
}

@media (min-width: 1024px) {
  .menu {
    width: 50%;
    left: 25%;

    &__title {
      margin: 2rem 0 0 2rem;
    }

    &__pin {
      width: 85%;

      &__container {
        border: 1px solid transparent;
        transition: 0.6s ease-in-out;
        &:hover {
          background: #a0a0a049;
          transition: 0.2s ease-in-out;
          cursor: pointer;
        }
      }
    }

    &__application {
      display: contents;

      &__card {
        width: 85%;
        margin: 15px auto;

        &--random {
          &-image {
            border-radius: 10px;
          }
        }
        
      }
    }

    &__bottom {
      &__profile {
        border: 1px solid transparent;
        padding: 3px 5px;

        &:hover {
          background: rgba(107, 107, 107, 0.151);
          backdrop-filter: blur( 5px );
          -webkit-backdrop-filter: blur( 5px );
          border-radius: 7px;
          border-top: 1px solid rgba(185, 185, 185, 0.18);
          border-left: 1px solid rgba(185, 185, 185, 0.18);
          border-bottom: 1px solid rgba(71, 71, 71, 0.151);
          border-right: 1px solid rgba(70, 70, 70, 0.151);
          -webkit-box-shadow: inset 5px 5px 20px 1px rgba(185, 185, 185, 0.18); 
          box-shadow: inset 5px 5px 20px 1px rgba(185, 185, 185, 0.18);
          cursor: pointer;
        }
      }

      &__off {
        border: 1px solid transparent;
        padding: 10px 15px;

        &:hover {
          background: rgba(107, 107, 107, 0.151);
          backdrop-filter: blur( 5px );
          -webkit-backdrop-filter: blur( 5px );
          border-radius: 7px;
          border-top: 1px solid rgba(185, 185, 185, 0.18);
          border-left: 1px solid rgba(185, 185, 185, 0.18);
          border-bottom: 1px solid rgba(71, 71, 71, 0.151);
          border-right: 1px solid rgba(70, 70, 70, 0.151);
          -webkit-box-shadow: inset 5px 5px 20px 1px rgba(185, 185, 185, 0.18); 
          box-shadow: inset 5px 5px 20px 1px rgba(185, 185, 185, 0.18);
          cursor: pointer;
        }
      }
    }
  }
}

@media (min-width: 1440px) {
  .menu {
    width: 30%;
    left: 35%;
  }
}

@keyframes pop-menu {
  from{
    bottom: -200px;
    opacity: 0;
  }

  to {
    bottom: 60px;
    opacity: 1;
  }
}

@keyframes pop-menu-close {
  from{
    bottom: 60px;
    opacity: 1;
  }

  to {
    bottom: -200px;
    opacity: 0;
  }
}
