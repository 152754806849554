@use 'src/styles/vars';

.navbar {
  display: flex;

  &__icon {
    width: 100vw;
    display: flex;
    position: absolute;
    bottom: 0px;
    z-index: 100;
    background-color: rgba(0, 0, 0, 0.527);

    &__list {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      backdrop-filter: blur(10px);
      border-top: 1px solid rgb(53, 53, 53);

      &__item {
        display: flex;
        flex-direction: column;

      }

      &__ploy {
        display: none;
      }
    }
  }

  &__trash {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 70px;
    left: 30px;

    &__icon {
      height: 50px;
    }

    &__title {
      font-family: vars.$font-regular;
      color: white;
      font-size: 0.8rem;
    }
  }

  &__info {
    width: 100vw;
    height: 2rem;
    display: flex;
    align-items: center;
    position: absolute;
    top: 0px;
    z-index: 100;
    background-color: rgba(0, 0, 0, 0.527);

    &__container {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: flex-end;
      padding-right: 1rem;
      align-items: center;
      backdrop-filter: blur(10px);
    }

    &__system {
      width: 80px;
      margin: 0px 10px;
      display: flex;
      justify-content: space-evenly;
    }

    &__icon {
      height: 20px;
    }

    &__toggle {
      transition: 0.3s ease-in-out;

      &.active {
        transform: rotate(-180deg);
        transition: 0.3s ease-in-out;
      }
    }

    &__datetime {
      font-family: vars.$font-regular;
      color: white;
      font-size: 0.8rem;
    }
  }
}

@media (min-width: 1024px) {
  .navbar {
    &__icon {
      &__list {
        &__img {
          margin: 4px;
          height: 40px;
          width: 40px;
          border: 1px solid transparent;
          border-radius: 7px;

          &:hover {
            background: rgba(107, 107, 107, 0.151);
            backdrop-filter: blur( 5px );
            -webkit-backdrop-filter: blur( 5px );
            border-radius: 7px;
            border-top: 1px solid rgba(185, 185, 185, 0.18);
            border-left: 1px solid rgba(185, 185, 185, 0.18);
            border-bottom: 1px solid rgba(71, 71, 71, 0.151);
            border-right: 1px solid rgba(70, 70, 70, 0.151);
            -webkit-box-shadow: inset 5px 5px 20px 1px rgba(185, 185, 185, 0.18); 
            box-shadow: inset 5px 5px 20px 1px rgba(185, 185, 185, 0.18);
            cursor: pointer;
          }

          border-bottom: 2px solid transparent;
          &.icon-active {
            border-bottom: 2px solid white;
            transition: ease-in 0.6s ;
          }
        }
      }
    }

    &__trash {
      left: 50px;
      padding: 15px 20px;
      border: 1px solid transparent;

      &:hover {
        background: rgba(107, 107, 107, 0.151);
        backdrop-filter: blur( 5px );
        -webkit-backdrop-filter: blur( 5px );
        border-radius: 7px;
        border-top: 1px solid rgba(185, 185, 185, 0.18);
        border-left: 1px solid rgba(185, 185, 185, 0.18);
        border-bottom: 1px solid rgba(71, 71, 71, 0.151);
        border-right: 1px solid rgba(70, 70, 70, 0.151);
        -webkit-box-shadow: inset 5px 5px 20px 1px rgba(185, 185, 185, 0.18); 
        box-shadow: inset 5px 5px 20px 1px rgba(185, 185, 185, 0.18);
        cursor: pointer;
      }
    }

    &__info {
      top: auto;
      bottom: 10px;
      right: 5px;
      background: none;
      width: 40vw;

      &__container {
        backdrop-filter: none;
      }

      &__system {
        width: 100px;
        padding: 10px 0;
        border: 1px solid transparent;

        &:hover {
          background: rgba(107, 107, 107, 0.151);
          backdrop-filter: blur( 5px );
          -webkit-backdrop-filter: blur( 5px );
          border-radius: 7px;
          border-top: 1px solid rgba(185, 185, 185, 0.18);
          border-left: 1px solid rgba(185, 185, 185, 0.18);
          border-bottom: 1px solid rgba(71, 71, 71, 0.151);
          border-right: 1px solid rgba(70, 70, 70, 0.151);
          -webkit-box-shadow: inset 5px 5px 20px 1px rgba(185, 185, 185, 0.18); 
          box-shadow: inset 5px 5px 20px 1px rgba(185, 185, 185, 0.18);
          cursor: pointer;
        }
      }

      &__icon {
        height: 20px;
      }

      &__toggle {
        height: 18px;
        padding: 11px;
        margin-right: 15px;
        border: 1px solid transparent;

        &:hover {
          background: rgba(107, 107, 107, 0.151);
          backdrop-filter: blur( 5px );
          -webkit-backdrop-filter: blur( 5px );
          border-radius: 7px;
          border-top: 1px solid rgba(185, 185, 185, 0.18);
          border-left: 1px solid rgba(185, 185, 185, 0.18);
          border-bottom: 1px solid rgba(71, 71, 71, 0.151);
          border-right: 1px solid rgba(70, 70, 70, 0.151);
          -webkit-box-shadow: inset 5px 5px 20px 1px rgba(185, 185, 185, 0.18); 
          box-shadow: inset 5px 5px 20px 1px rgba(185, 185, 185, 0.18);
          cursor: pointer;
        }
      }

      &__datetime {
        font-size: 0.9rem;
        text-align: end;
        padding: 6px;
        border: 1px solid transparent;

        &:hover {
          background: rgba(107, 107, 107, 0.151);
          backdrop-filter: blur( 5px );
          -webkit-backdrop-filter: blur( 5px );
          border-radius: 7px;
          border-top: 1px solid rgba(185, 185, 185, 0.18);
          border-left: 1px solid rgba(185, 185, 185, 0.18);
          border-bottom: 1px solid rgba(71, 71, 71, 0.151);
          border-right: 1px solid rgba(70, 70, 70, 0.151);
          -webkit-box-shadow: inset 5px 5px 20px 1px rgba(185, 185, 185, 0.18); 
          box-shadow: inset 5px 5px 20px 1px rgba(185, 185, 185, 0.18);
          cursor: pointer;
        }
      }
    }
  }
}

