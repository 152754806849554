@use "src/styles/vars";

.hobby {
  display: none;
  &.active {
    display: block;
    color: aliceblue;
    background-color: #2f373d;
    animation: toggleDetails 0.5s ease-in-out;
    padding: 10px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  &__container {
    width: 100%;
    height: 100px;
    border-radius: 5px;
    margin-bottom: 10px;

    &__effect {
      width: 100%;
      height: 100px;
      background-color: #0606071f;
      border-radius: 5px;
      backdrop-filter: blur(1.5px);
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__text {
      font-family: vars.$font-regular;
      color: white;
    }

    &.guitar {
      background: url(../../../../assets/img/hobby/guitar.jpg) no-repeat;
      background-position: center;
      background-size: cover;
    }

    &.video-game {
      background: url(../../../../assets/img/hobby/video-game.jpg) no-repeat;
      background-position: center;
      background-size: cover;
    }

    &.programming {
      background: url(../../../../assets/img/hobby/programming.jpg) no-repeat;
      background-position: center;
      background-size: cover;
    }

    &.crypto {
      background: url(../../../../assets/img/hobby/bitcoin.jpg) no-repeat;
      background-position: center;
      background-size: cover;
    }

    &.board-game {
      background: url(../../../../assets/img/hobby/board-game.jpg) no-repeat;
      background-position: center;
      background-size: cover;
    }

    &.smart-home {
      background: url(../../../../assets/img/hobby/smart-home.jpg) no-repeat;
      background-position: center;
      background-size: cover;
    }

    &.travel {
      background: url(../../../../assets/img/hobby/travel.jpg) no-repeat;
      background-position: center;
      background-size: cover;
    }

    &.martial-art {
      background: url(../../../../assets/img/hobby/martial-art.jpg) no-repeat;
      background-position: center;
      background-size: cover;
    }

    &.robotic {
      background: url(../../../../assets/img/hobby/robots.jpg) no-repeat;
      background-position: center;
      background-size: cover;
    }

    &.electronic {
      background: url(../../../../assets/img/hobby/electronic.jpg) no-repeat;
      background-position: center;
      background-size: cover;
    }

    &.printer {
      background: url(../../../../assets/img/hobby/imprimante-3d.png) no-repeat;
      background-position: center;
      background-size: cover;
    }

    &.ia {
      background: url(../../../../assets/img/hobby/ia.jpg) no-repeat;
      background-position: center;
      background-size: cover;
    }
  }
}


@media (min-width: 768px) {
  .hobby {
    &.active {
      padding: 10px 30px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    &__container {
      width: 47.5%;

      &__text {
        font-size: 19px;
        text-align: center;
        margin: 5px;
      }
    }
  }
}

@media (min-width: 1024px) {
  .hobby {
    &__container {
      width: 24%;

      &__effect {
        transition: 0.4s ease-in-out;
        cursor: default;

        &:hover {
          backdrop-filter: blur(3px);
          transition: 0.1s ease-in-out;
          transform: scale(1.05);
        }
      }
    }
  }
}

@keyframes toggleDetails {
  from {
    opacity: 0;
    transform: translateY(-40%);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}