@use "src/styles/vars";

.error-win {
  z-index: 500;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 90%;
  background-color: #C4C2C4;
  border-top: 4px solid #e9e9e9;
  border-left: 4px solid #e9e9e9;
  border-bottom: 4px solid #7e7e7e;
  border-right: 4px solid #7e7e7e;
  font-family: vars.$font-win;

  &__title-bar {
    padding: 3px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: rgb(0,0,130);
    background: linear-gradient(90deg, rgba(0,0,130,1) 0%, rgba(22,136,214,1) 100%);
    cursor: default;

    &__title {
      font-weight: 300;
      color: white;
      margin-left: 5px;
    }

    &__quit {
      display: block;
      padding: 2px 5px;
      background-color: #C4C2C4;
      border-top: 2px solid #e9e9e9;
      border-left: 2px solid #e9e9e9;
      border-bottom: 2px solid #474747;
      border-right: 2px solid #474747;
      font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
      font-weight: bold;
      color: #474747;
    }
  }

  &__content {
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: default;

    &__container {
      display: flex;
      width: 100%;

      &__image {
        width: 45px;
        height: 45px;
      }

      &__text {
        margin-left: 15px;
        margin-bottom: 20px;
      }
    }

    &__button {
      border-top: 2px solid #313131;
      border-left:  2px solid #313131;
      border-bottom: 4px solid #313131;
      border-right: 4px solid #313131;
      padding: 3px;
      cursor: pointer;

      &__text {
        padding: 2px 48px;
        border: 1px dashed #313131;
      }
    }
  }
}

@media (min-width: 1024px) {
  .error-win {
    width: 20%;
  }
}