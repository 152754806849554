@use "src/styles/vars";

.study {
  display: none;
  &.active {
    display: block;
    color: aliceblue;
    background-color: #2f373d;
    animation: toggleDetails 0.5s ease-in-out;
    padding: 10px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  &__card {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    &__image {
      margin-right: 10px;
    }

    &__text {
      font-family: vars.$font-regular;
      font-size: 15px;
    }
  }
}

@media (min-width: 768px) {
  .study {
    &.active {
      padding: 10px 30px;
    }

    &__card {
      &__text {
        font-size: 17px;
      }
    }
  }
}

@keyframes toggleDetails {
  from {
    opacity: 0;
    transform: translateY(-40%);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}