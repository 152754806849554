@use 'src/styles/_vars';

.home-profile {
  &__mobile-alert {
    position: absolute;
    top: 5px;
    left: 5px;

    font-family: vars.$font-medium;
    color: black;
  }

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__picture {
    width: 250px;
    height: 250px;
    border-radius: 50%;
    -webkit-box-shadow: 0px 0px 18px -1px #000000; 
    box-shadow: 0px 0px 15px -1px #000000;
    margin-bottom: 1rem;
    cursor: help;
  }

  &__name {
    font-family: vars.$font-medium;
    font-size: 1.7rem;
    color: rgb(219, 219, 219);
    margin-bottom: 1.5rem;
    cursor: default;
  }

  &__form {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__input {
      background-color: #32363F;
      color: rgb(219, 219, 219);
      height: 1.8rem;
      width: 12rem;
      border-radius: 5px;
      border: 2px solid #556177;
      padding-left: 0.5rem;
      margin-bottom: 1rem;

      &:focus {
        border: 2px solid #737f96;
        outline: none;
        border-bottom:2px solid #1572B7;
      }
    }

    &__label {
      padding: 1rem;
      border-radius: 5px;
      background-color: #0000003f;
      color: rgb(219, 219, 219);
      font-family: vars.$font-regular;
      width: 80%;
    }
  }

  &__info {
    &__device {
      display: flex;
      justify-content: space-around;
      align-items: center;
      width: 180px;
      position: absolute;
      bottom: 10px;
      right: 10px;

      &__icon {
        height: 1.5rem;
      }

      &__time {
        color: rgb(219, 219, 219);
        font-family: vars.$font-regular;
      }
    }
  }

}

@media (min-width: 1024px) {
  .home-profile {
    &__form {
      &__input {
        height: 2rem;
        width: 15rem;
        font-size: 0.9rem;
      }
    }
    &__mobile-alert {
      display: none;
    }

    &__info {
      &__device {
        width: 250px;
      }
    }
  }
}