@use "src/styles/vars";

.about {
  background-color: #1B2126;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100vw;
  height: 80vh;
  border-radius: 10px;
  -webkit-box-shadow: 0px 0px 14px 2px #000000; 
  box-shadow: 0px 0px 14px 2px #000000;
  overflow: hidden;
  animation: scale 0.3s ease-in-out;


  &__title-bar {
    background-color: #1B2125;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 5%;
    padding: 5px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    &__left {
      display: flex;
      align-items: center;
      width: fit-content;

      &__icon {
        width: 30px;
        height: 30px;
      }

      &__title {
        color: rgb(194, 193, 193);
        font-family: vars.$font-regular;
        margin-left: 5px;
      }
    }

    &__right {
      display: flex;
      justify-content: space-around;
      width: 90px;

      &__icons {
        width: 15px;
        height: 15px; 
      }
    }
  }

  &__path {
    font-family: vars.$font-regular;
    color: rgb(168, 168, 168);
    font-size: 20px;
    margin: 15px 10px;

    & span {
      color: white;
    }
  }

  &__content {
    height: 83%;
    width: 100%;
    overflow-y: scroll;
    
    &::-webkit-scrollbar {
      width: 3px;
    }
  
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
  
    &::-webkit-scrollbar-thumb {
      background-color: grey;
      border-radius: 3px;

      &:hover {
        background-color: rgb(187, 187, 187);
      }
    }

    &__header {
      width: 100%;
      display: flex;
      flex-direction: column;

      &__profile {
        display: flex;
        align-items: center;
        margin: 15px;

        &__image {
          width: 140px;
          height: 140px;
          border-radius: 50%;
          margin-right: 20px;
        }

        &__details {
          color: white;
          font-family: vars.$font-regular;

          &--lastname, &--name {
            font-size: 25px;
            font-family: vars.$font-medium;
          }

          &--name {
            margin-bottom: 5px;
          }

          &--birthday, &--status {
            font-size: 15px;
            color: rgb(168, 168, 168);
          }
        }
      }

      &__status {
        text-align: center;
        &__title {
          color: white;
          font-family: vars.$font-medium;
          font-size: 30px;
        }
        &__download {
          color: white;
          font-family: vars.$font-medium;
          font-size: 20px;
        }
      }
    }

    &__section-title {
      color: white;
      font-family: vars.$font-regular;
      margin-left: 3%;
      margin-top: 25px;
      margin-bottom: 10px;
    }

    &__card {
      width: 90%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0px auto;
      background-color: #262D32;
      padding: 8px;
      border-radius: 5px;
      position: relative;
      z-index: 100;
      transition: 0.5s ease-in-out;

      &.active {
        background-color: #3a4146;
        transition: 0.2s ease-in-out;
      }

      &__label {
        display: flex;
        align-items: center;

        &__image {
          width: 25px;
          margin-right: 8px;
        }

        &__title {
          font-family: vars.$font-regular;
          color: rgb(221, 218, 218);
        }
      }

      &__arrow {
        width: 20px;
        height: 20px;
        transition: 0.5s ease-in-out;

        &.active {
          transform: rotate(180deg);
          transition: 0.5s ease-in-out;
        }
      }
    }

    &__detail {
      margin: 0px auto;
      margin-bottom: 10px;
      width: 90%;
    }
  }
}

.hidden-container {
  overflow: hidden;
}

@media (min-width: 768px) {
  .about {
    top: 45%;
    left: 48%;
    width: 85vw;
    height: 65vh;
    transition: all 0.5s ease-in-out;

    &.active {
      top: 2rem;
      left: 0%;
      transform: none;
      width: 100vw;
      height: 90vh;
      transition: all 0.5s ease-in-out;
    }

    &__content {
      &__header {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        &__status {
          margin-right: 25px;
        }
      }

      &__section-title {
        margin-left: 5%;
      }
    }
  }
}

@media (min-width:1024px) {
  .about {
    width: 60vw;
    top: 40%;
    left: 60%;

    &.active {
      top: 0;
      height: 95vh;

      .about {
        &__content {
          &__header {
            margin-left: 100px;
            margin-top: 50px;
            margin-bottom: 50px;
            width: 50%;
          }
        }
      }
    }

    &__title-bar {
      &__left {
        &__title {
          cursor: default;
        }
      }
      &__right {
        width: 100px;
        &__icons {
          padding: 10px;
          margin-right: 2px;
          cursor: pointer;
          &:hover {
            background-color: rgb(86, 88, 88);
          }

          &--close {
            border-top-right-radius: 5px;
            &:hover {
              background-color: red;
            }
          }
        }
      }
    }

    &__content {
      &__header {
        margin-left: 50px;
        width: 70%;
        &__status {
          &__download {
            &:hover {
              color: rgb(168, 168, 168);
            }
          }
        }
      }

      &__card {
        &:hover {
          background-color: #3a4146;
          transition: 0.2s ease-in-out;
          cursor: pointer;
        }
      }
    }
  }
}

@keyframes scale {
  from {
    top: 60%;
    left: 25%;
    transform: scale(0) translate(-100%, 50%);
  }
  to {
    transform: scale(1) translate(-50%, -50%);
  }
}